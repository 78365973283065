import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import Seo from '../components/Seo/Seo';
import Layout from '../components/layout';
import Experience from '../modules/Experience';

const PageTemplate = (props) => {
  const post = props.data.post;
  const { location } = props;
  const handleScroll = () => {
    if (window.pageYOffset > 100) {
      document.body.classList.add('scroll-down');
    }
    if (window.pageYOffset <= 100) {
      document.body.classList.remove('scroll-down');
    }
    if (window.pageYOffset > 10) {
      document.body.classList.add('header-sticky');
    }
    if (window.pageYOffset <= 10) {
      document.body.classList.remove('header-sticky');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <Layout props={props} location={location}>
        <Seo seo={post.seo} />
        <Experience modules={post.experiencesContent} />
      </Layout>
    </>
  );
};

export default PageTemplate;
export const pageQuery = graphql/* GraphQL */ `
  query experienceById($id: String!) {
    post: wpExperience(id: { eq: $id }) {
      id
      title
      featuredImage {
        node {
          altText
        }
      }
      seo {
        fullHead
        metaDesc
        canonical
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
      }
      ...ModuleExperienceFragment
    }
  }
`;
