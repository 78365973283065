import { graphql, useStaticQuery } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const RelatedCards = ({ module }) => {
  const data = useStaticQuery(graphql/* GraphQL */ `
    query {
      wp {
        fluxDnaSettings {
          fluxDna {
            global {
              cards {
                subheading
                preheading
                heading
                fieldGroupName
                description
                cta {
                  target
                  title
                  url
                }
                image {
                  altText
                  uri
                  imgixImage {
                    fluid(
                      maxWidth: 420
                      maxHeight: 460
                      imgixParams: { w: 420, h: 460, fit: "crop" }
                    ) {
                      srcWebp
                    }
                  }
                  title
                }
              }
            }
            globalBirkenheadHouse {
              cards {
                subheading
                preheading
                heading
                fieldGroupName
                description
                cta {
                  target
                  title
                  url
                }
                image {
                  altText
                  uri
                  imgixImage {
                    fluid(
                      maxWidth: 420
                      maxHeight: 460
                      imgixParams: { w: 420, h: 460, fit: "crop" }
                    ) {
                      srcWebp
                    }
                  }
                  title
                }
              }
            }
            globalLaResidence {
              cards {
                subheading
                preheading
                heading
                fieldGroupName
                description
                cta {
                  target
                  title
                  url
                }
                image {
                  altText
                  uri
                  imgixImage {
                    fluid(
                      maxWidth: 420
                      maxHeight: 460
                      imgixParams: { w: 420, h: 460, fit: "crop" }
                    ) {
                      srcWebp
                    }
                  }
                  title
                }
              }
            }
            globalRoyalMalewane {
              cards {
                subheading
                preheading
                heading
                fieldGroupName
                description
                cta {
                  target
                  title
                  url
                }
                image {
                  altText
                  uri
                  imgixImage {
                    fluid(
                      maxWidth: 420
                      maxHeight: 460
                      imgixParams: { w: 420, h: 460, fit: "crop" }
                    ) {
                      srcWebp
                    }
                  }
                  title
                }
              }
            }
            globalTheSilo {
              cards {
                subheading
                preheading
                heading
                fieldGroupName
                description
                cta {
                  target
                  title
                  url
                }
                image {
                  altText
                  uri
                  imgixImage {
                    fluid(
                      maxWidth: 420
                      maxHeight: 460
                      imgixParams: { w: 420, h: 460, fit: "crop" }
                    ) {
                      srcWebp
                    }
                  }
                  title
                }
              }
            }
          }
        }
      }
    }
  `);

  let cards = [];
  switch (module.property) {
    case 'The Royal Portfolio': {
      cards = data.wp.fluxDnaSettings.fluxDna.global.cards;
      break;
    }
    case 'Birkenhead House': {
      cards = data.wp.fluxDnaSettings.fluxDna.globalBirkenheadHouse.cards;
      break;
    }
    case 'La Residence': {
      cards = data.wp.fluxDnaSettings.fluxDna.globalLaResidence.cards;
      break;
    }
    case 'Royal Malewane': {
      cards = data.wp.fluxDnaSettings.fluxDna.globalRoyalMalewane.cards;
      break;
    }
    case 'The Silo': {
      cards = data.wp.fluxDnaSettings.fluxDna.globalTheSilo.cards;
      break;
    }
    case 'Custom': {
      cards = module.cards
        ? module.cards.map((item) => ({
            heading: item.cardHeading,
            description: item.cardContent,
            image: item.cardImage,
            cta: item.cta,
          }))
        : [];
      break;
    }
    default: {
      cards = [];
    }
  }

  const handleNextClickStay = () => {
    const swiper = document.querySelector(
      '.related-cards-mobile-slider .swiper'
    ).swiper;
    swiper.slideNext();
  };
  const handlePrevClickStay = () => {
    const swiper = document.querySelector(
      '.related-cards-mobile-slider .swiper'
    ).swiper;
    swiper.slidePrev();
  };

  return (
    <div
      className={`explore-card lg:pt-60 pt-60 lg:pb-60 pb-30 mdscreen:pt-0 mdscreen:pb-0 bg-white ${module.extraClass}`}
    >
      <div className="container-fluid fade-img lg:block mdscreen:hidden smscreen2:px-0">
        <Swiper
          loop={false}
          allowTouchMove={true}
          slidesPerView={3}
          spaceBetween={40}
        >
          {cards &&
            cards.map((item, i) => {
              return (
                item.image && (
                  <SwiperSlide key={i}>
                    <div className="card-bx">
                      <div className="card-box-image">
                        {item.image && (
                          <a href={item.cta.url}>
                            <img
                              src={item.image.imgixImage.fluid.srcWebp}
                              alt=""
                              className="w-full h-[30vw]"
                              width="600"
                              height="600"
                              loading="lazy"
                            />
                          </a>
                        )}
                      </div>
                      <div className="content pt-30 lg:pr-20 pr-0 smscreen2:text-center smscreen:px-20">
                        <a href={item.cta.url}>
                          <h4 className="lg:text-32 text-26 lg:leading-39 leading-30 xlscreen:text-28 text-black-300 mdscreen:text-26 mdscreen:leading-30">
                            {item.heading}
                          </h4>
                          {item.description && parse(item.description)}
                        </a>
                        <div className="btn-custom mt-20 smscreen:text-center smscreen:mt-10 ">
                          {item.cta && (
                            <a href={item.cta.url} className="link-btn">
                              {item.cta.title}
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              );
            })}
        </Swiper>
      </div>
      <div className="container-fluid related-cards-mobile-slider fade-img lg:hidden mdscreen:block smscreen2:px-0">
        <Swiper
          loop={true}
          allowTouchMove={true}
          slidesPerView={1.5}
          spaceBetween={20}
          navigation={true}
          modules={[Navigation]}
          centeredSlides={true}
          speed={500}
        >
          {cards &&
            cards.map((item, i) => {
              return (
                item.image && (
                  <SwiperSlide key={i}>
                    <div key={i} className="card-bx mt-60 mb-30">
                      <div className="card-box-image">
                        {item.image && (
                          <img
                            src={item.image.imgixImage.fluid.srcWebp}
                            alt=""
                            width="600"
                            height="600"
                            className="w-full object-cover object-center"
                            loading="lazy"
                          />
                        )}
                      </div>
                      <div className="content pt-30 lg:pr-100 pr-0 smscreen2:text-center smscreen:px-20">
                        <h4>{item.heading}</h4>
                        {item.description && parse(item.description)}
                        <div className="btn-custom mt-20 smscreen:text-center smscreen:mt-10 ">
                          {item.cta && (
                            <a href={item.cta.url} className="link-btn">
                              {item.cta.title}
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              );
            })}
          <div className="swiper-arrow  flex items-center justify-between w-full visible z-9 top-[43.5vw] px-[37px]">
            <a
              className="button-prev cursor-pointer"
              onClick={handlePrevClickStay}
            ></a>
            <a
              className="button-next cursor-pointer"
              onClick={handleNextClickStay}
            ></a>
          </div>
        </Swiper>
      </div>
    </div>
  );
};

export default RelatedCards;

export const RelatedCardsFragment = graphql`
  fragment RelatedCardsFragment on WpPage_Flexiblecontent_FlexibleContent_RelatedCards {
    # content
    property
    cards {
      cardHeading
      cardContent
      cta {
        url
        title
        target
      }
      cardImage {
        altText
        uri
        imgixImage {
          fluid(
            maxWidth: 420
            maxHeight: 460
            imgixParams: { w: 420, h: 460, fit: "crop" }
          ) {
            srcWebp
          }
        }
        title
      }
    }
  }
`;
export const RelatedPropertyCardsFragment = graphql`
  fragment RelatedPropertyCardsFragment on WpProperty_Flexiblecontent_FlexibleContent_RelatedCards {
    # content
    property
    extraClass
    cards {
      cardHeading
      cardContent
      cta {
        url
        title
        target
      }
      cardImage {
        altText
        uri
        imgixImage {
          fluid(
            maxWidth: 420
            maxHeight: 460
            imgixParams: { w: 420, h: 460, fit: "crop" }
          ) {
            srcWebp
          }
        }
        title
      }
    }
  }
`;
export const RelatedCardsExperienceFragment = graphql`
  fragment RelatedCardsExperienceFragment on WpExperience_Experiencescontent_ExperiencesContent_RelatedCards {
    # content
    property
    cards {
      cardHeading
      cardContent
      cta {
        url
        title
        target
      }
      cardImage {
        altText
        uri
        imgixImage {
          fluid(
            maxWidth: 420
            maxHeight: 460
            imgixParams: { w: 420, h: 460, fit: "crop" }
          ) {
            srcWebp
          }
        }
        title
      }
    }
  }
`;
export const PrivateResidenceRelatedCardsFragment = graphql`
  fragment PrivateResidenceRelatedCardsFragment on WpPrivateResidence_Flexiblecontent_FlexibleContent_RelatedCards {
    # content
    property
    cards {
      cardHeading
      cardContent
      cta {
        url
        title
        target
      }
      cardImage {
        altText
        uri
        imgixImage {
          fluid(
            maxWidth: 420
            maxHeight: 460
            imgixParams: { w: 420, h: 460, fit: "crop" }
          ) {
            srcWebp
          }
        }
        title
      }
    }
  }
`;
export const ItineraryRelatedCardsFragment = graphql`
  fragment ItineraryRelatedCardsFragment on WpItinerary_Itinerarycontent_TripContent_RelatedCards {
    fieldGroupName
    property
    cards {
      cardContent
      cardHeading
      cardImage {
        altText
        imgixImage {
          fluid(
            maxWidth: 420
            maxHeight: 460
            imgixParams: { w: 420, h: 460, fit: "crop" }
          ) {
            srcWebp
          }
        }
      }
      cta {
        target
        title
        url
      }
      fieldGroupName
    }
  }
`;
export const RelatedCardsPressFragment = graphql`
  fragment RelatedCardsPressFragment on WpPress_Flexiblecontent_FlexibleContent_RelatedCards {
    fieldGroupName
    property
    cards {
      cardContent
      cardHeading
      cardImage {
        altText
        imgixImage {
          fluid(
            maxWidth: 420
            maxHeight: 460
            imgixParams: { w: 420, h: 460, fit: "crop" }
          ) {
            srcWebp
          }
        }
      }
      cta {
        target
        title
        url
      }
      fieldGroupName
    }
  }
`;
export const RelatedCardsBlogFragment = graphql`
  fragment RelatedCardsBlogFragment on WpPost_Blogcontent_BlogContent_RelatedCards {
    fieldGroupName
    property
    cards {
      cardContent
      cardHeading
      cardImage {
        altText
        imgixImage {
          fluid(
            maxWidth: 420
            maxHeight: 460
            imgixParams: { w: 420, h: 460, fit: "crop" }
          ) {
            srcWebp
          }
        }
      }
      cta {
        target
        title
        url
      }
      fieldGroupName
    }
  }
`;
