import { graphql, Link, useStaticQuery } from 'gatsby';
import parse from 'html-react-parser';
import React, { useState } from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const ExperiencesSlider = ({ module }) => {
  const filterData = useStaticQuery(graphql`
    query {
      allWpExperienceType {
        nodes {
          id
          name
        }
      }
    }
  `);

  const pageSize = 12;
  const [displayedItemsCount, setDisplayedItemsCount] = useState(pageSize);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const categories = filterData?.allWpExperienceType?.nodes || [];
  const data = module?.experiences || module?.experience || [];
  const experiences =
    selectedFilter === 'all'
      ? data
      : data?.length > 0
      ? data?.filter((item) => {
          let isCat = item?.experienceType?.nodes.filter(
            (catItem) => catItem.id === selectedFilter
          );
          if (isCat?.length > 0) {
            return true;
          }
          return false;
        })
      : [];

  const onFilterChange = (e) => {
    setSelectedFilter(e.target.value);
  };

  const onLoadMoreClick = () => {
    setDisplayedItemsCount(displayedItemsCount + pageSize);
  };

  const handleNextClickStay = () => {
    const swiper = document.querySelector('.experience-slider .swiper').swiper;
    swiper.slideNext();
  };
  const handlePrevClickStay = () => {
    const swiper = document.querySelector('.experience-slider .swiper').swiper;
    swiper.slidePrev();
  };

  return (
    <>
      {module.variation === 'Grid' ? (
        <section
          className={`lg:py-60 py-30 img-grid portrait lg:mb-60 mb-30 ${
            module?.extraClass
          } ${module?.hideFilter && data?.length > 0 ? 'filter-padding' : ''}`}
        >
          <div className="container-fluid">
            {module?.hideFilter && data?.length > 0 && (
              <div className="container m-auto pb-30">
                <div className="filter-list text-center relative mdscreen:pb-10 mdscreen:border-b-[1px] mdscreen:border-b-[#666366]/40">
                  <select onChange={onFilterChange}>
                    <option value="all">All Experiences</option>
                    {categories?.map((item, i) => {
                      return (
                        <option value={item.id} key={item.id}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}
            <div className="flex flex-wrap lg:mx-minus-20 lg:gap-y-16 gap-y-10 ">
              {experiences?.length > 0 &&
                experiences
                  ?.slice(0, displayedItemsCount)
                  .map((item, index) => {
                    return (
                      <div
                        className={`lg:w-6/12 w-full lg:px-20 fade-img`}
                        key={item?.id || index.toString()}
                      >
                        <Link to={item.uri}>
                          <img
                            src={item?.featuredImage?.node.imgixImage.url}
                            alt={item?.featuredImage?.node?.altText}
                            className="w-full"
                            width="890"
                            height="647"
                            loading="lazy"
                          />
                        </Link>

                        <div className="img-content pt-30 max-w-540">
                          <Link to={item.uri}>
                            {item?.preheading && (
                              <span className="text-13 text-dark font-400 uppercase leading-22 mb-10 inline-block font-preheaders">
                                {item.preheading}
                              </span>
                            )}
                            <h4 className="text-black-200"> {item.title}</h4>
                            {module?.hideFilter &&
                              item?.categories?.nodes &&
                              item?.categories?.nodes?.length > 0 && (
                                <span className="text-11 text-dark font-400 uppercase leading-22 mb-10 block">
                                  {item?.categories?.nodes[0].name}
                                </span>
                              )}
                          </Link>
                          {item?.excerpt && (
                            <div className="content text-sm my-15">
                              {parse(item?.excerpt)}
                            </div>
                          )}
                          {item?.propertyContent?.ctaTitle ? (
                            <Link to={item.uri} className="link-btn">
                              {item?.propertyContent?.ctaTitle}
                            </Link>
                          ) : (
                            <div>
                              <Link to={item.uri} className="link-btn">
                                Explore
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              {displayedItemsCount < experiences.length &&
                experiences.length > 1 && (
                  <div className="btn-custom w-full justify-center text-center cursor-pointer ">
                    <a
                      onClick={onLoadMoreClick}
                      className="button button-transparent bg-[#e2e2e2] mt-30"
                    >
                      LOAD MORE EXPERIENCES
                    </a>
                  </div>
                )}
            </div>

            {experiences?.length === 0 && (
              <div className="no-record-found">
                <div className="container-fluid-md">
                  <div className="bg-white shadow-card lg:py-120 py-60">
                    <div className="text-center">
                      <h2>No Available Experiences</h2>
                      <div className="content mt-20 max-w-500 m-auto">
                        <p>
                          There don't seem to be any available experiences at
                          the moment. please check in again at a later date.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      ) : (
        <section
          className={`experience-slider full-bx-slider bg-white lg:py-60 py-30 ${module.extraClass}`}
        >
          <Swiper
            loop={true}
            centeredSlides={true}
            navigation={true}
            speed={1000}
            pagination={{
              type: 'progressbar',
            }}
            modules={[Pagination]}
            breakpoints={{
              0: {
                slidesPerView: 1.5,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 2.2,
                spaceBetween: 20,
              },
            }}
          >
            {experiences?.length > 0 &&
              experiences?.slice(0, displayedItemsCount).map((item, i) => {
                return (
                  <SwiperSlide key={item.id}>
                    <div className="card-bx">
                      <div className="gallery-img-slider relative">
                        <img
                          src={item?.featuredImage?.node.imgixImage.url}
                          alt={item?.featuredImage?.node?.altText}
                          className=""
                          loading="lazy"
                        />
                        <div className="swiper-arrow  flex items-center justify-between w-full top-50-per translate-y-minus_50 px-20">
                          <a
                            className="button-prev cursor-pointer"
                            onClick={handlePrevClickStay}
                          ></a>
                          <a
                            className="button-next cursor-pointer"
                            onClick={handleNextClickStay}
                          ></a>
                        </div>
                      </div>
                      <div className="content pt-30 lg:pr-100 pr-0 smscreen2:text-center smscreen:px-20">
                        <h6>{item?.title}</h6>
                        <p>{item?.excerpt && parse(item.excerpt)}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </section>
      )}
    </>
  );
};

export default ExperiencesSlider;

export const ExperiencesSliderExperienceFragment = graphql`
  fragment ExperiencesSliderExperienceFragment on WpExperience_Experiencescontent_ExperiencesContent_ExperiencesSlider {
    # content
    fieldGroupName
    variation
    heading
    hideFilter
    extraClass
    experiences {
      ... on WpProperty {
        id
        title
        uri
        excerpt
        featuredImage {
          node {
            id
            altText
            imgixImage {
              url(imgixParams: { w: 650, h: 473, q: 95, fit: "crop" })
            }
            uri
            title
          }
        }
        experienceType {
          nodes {
            name
            id
          }
        }
        categories {
          nodes {
            id
            name
          }
        }
        propertyContent {
          ctaTitle
          experienceContent
          content
          heading
          subheading
          mapImage {
            id
            altText
            title
            sourceUrl
            uri
          }
        }
      }
    }
  }
`;
export const ExperiencesSliderPropertyFragment = graphql`
  fragment ExperiencesSliderPropertyFragment on WpProperty_Flexiblecontent_FlexibleContent_ExperiencesSlider {
    # content
    fieldGroupName
    variation
    hideFilter
    experience {
      ... on WpProperty {
        id
        title
        uri
        excerpt
        featuredImage {
          node {
            id
            altText
            imgixImage {
              url(imgixParams: { w: 650, h: 473, q: 95, fit: "crop" })
            }
            uri
            title
          }
        }
        categories {
          nodes {
            id
            name
          }
        }
        experienceType {
          nodes {
            name
            id
          }
        }
        propertyContent {
          ctaTitle
          experienceContent
          content
          heading
          subheading
          mapImage {
            id
            altText
            title
            sourceUrl
            imgixImage {
              url(imgixParams: { w: 650, h: 473, q: 95, fit: "crop" })
            }
            uri
          }
        }
      }
    }
  }
`;
