import React, { useEffect } from 'react';
import Footer from './Footer/Footer';
import Header from './Header/Header';

const Layout = ({ children, location }) => {
  useEffect(() => {
    document.body.classList.remove('menu-open');
  }, []);

  return (
    <main className="grid-container">
      <div className="global-wrapper">
        <Header location={location} />
        {children}
        <Footer />
        <div className="hidden my-40 lg:text-28 text-22 max-w-515 inner-page-banner desk-pt-remove mb-pt-remove bg-gray pb-0 mt-0 mt-30 lg:mt-60 margint-0 paddingb-0 my-30 lg:my-60 desk-pt-remove desk-pb-remove desk-pl-remove desk-pr-remove desk-mt-remove desk-mb-remove mb-pt-remove mb-pb-remove mb-pl-remove mb-pr-remove mb-mt-remove mb-mb-remove bg_white bg_gray bg_transparent whats-on"></div>
      </div>
    </main>
  );
};

export default Layout;
