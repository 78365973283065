import { graphql, Link } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';

const IntroContent = ({ module }) => {
  return (
    <>
      <section
        id={`${module.extraId}`}
        className={`section-title lg:p-40 py-20 smscreen2:pb-0 ${module.extraClass}`}
      >
        <div className="fade-ani px-30 text-center">
          <div className="title-black text-center">
            {module.preheading && (
              <span className="text-16 font-400 text-dark uppercase !mb-10">
                {module.preheading}
              </span>
            )}
            <h1>{module.heading && parse(module.heading)}</h1>
            {module.subheading && <p className="smscreen2:mt-10 mt-30">{module.subheading}</p>}
          </div>
          {module.description && (
            <div className="content max-w-[650px] m-auto text-md text-center mt-20">
              {parse(module.description)}
            </div>
          )}
          {module.cta && (
            <div className="btn-custom mt-25 flex justify-center items-center gap-8 smscreen2:gap-4 flex-wrap">
              {module.cta && (
                <Link
                  to={module.cta.url}
                  target={module.cta.target}
                  className="button button-transparent"
                >
                  {module.cta.title}
                </Link>
              )}
              {module.additionalCta && (
                <Link
                  to={module.additionalCta.url}
                  target={module.additionalCta.target}
                  className="link-btn"
                >
                  {module.additionalCta.title}
                </Link>
              )}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default IntroContent;

export const IntroContentFragment = graphql`
  fragment IntroContentFragment on WpPage_Flexiblecontent_FlexibleContent_IntroContent {
    # content
    description
    extraClass
    extraId
    fieldGroupName
    subheading
    preheading
    heading
    cta {
      url
      title
      target
    }
  }
`;
export const FoundationIntroContentFragment = graphql`
  fragment FoundationIntroContentFragment on WpProperty_Flexiblecontent_FlexibleContent_IntroContent {
    # content
    description
    extraId
    extraClass
    fieldGroupName
    subheading
    preheading
    heading
    cta {
      url
      title
      target
    }
  }
`;
export const IntroContentExperienceFragment = graphql`
  fragment IntroContentExperienceFragment on WpExperience_Experiencescontent_ExperiencesContent_IntroContent {
    # content
    description
    extraId
    fieldGroupName
    subheading
    preheading
    heading
    cta {
      url
      title
      target
    }
  }
`;
export const ItineraryIntroContentFragment = graphql`
  fragment ItineraryIntroContentFragment on WpItinerary_Itinerarycontent_TripContent_IntroContent {
    # content
    description
    extraId
    fieldGroupName
    preheading
    subheading
    heading
    cta {
      url
      title
      target
    }
    additionalCta {
      target
      title
      url
    }
  }
`;
export const IntroContentPropertyFragment = graphql`
  fragment IntroContentPropertyFragment on WpProperty_Flexiblecontent_FlexibleContent_IntroContent {
    # content
    description
    extraId
    extraClass
    fieldGroupName
    subheading
    preheading
    heading
    cta {
      url
      title
      target
    }
  }
`;
export const IntroContentPressFragment = graphql`
  fragment IntroContentPressFragment on WpPress_Flexiblecontent_FlexibleContent_IntroContent {
    # content
    description
    extraId
    fieldGroupName
    subheading
    preheading
    heading
    cta {
      url
      title
      target
    }
  }
`;
