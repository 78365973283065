import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const Gallery = ({ module }) => {
  const imageFixLimit = 12;
  const handleNextClickStay = () => {
    const swiper = document.querySelector('.full-bx-slider .swiper').swiper;
    swiper.slideNext();
  };
  const handlePrevClickStay = () => {
    const swiper = document.querySelector('.full-bx-slider .swiper').swiper;
    swiper.slidePrev();
  };

  const [imageLimit, setImageLimit] = useState(imageFixLimit);
  const [fixLimit, setfixLimit] = useState(
    module.tabs ? module.tabs.length : 0
  );
  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    if (module.tabs?.length > 0) {
      setGallery(() => {
        return module.tabs.slice(0, imageLimit);
      });
    }
  }, [imageLimit]);

  function filterData(id) {
    if (id === 'all') {
      setImageLimit(imageFixLimit);
      setGallery(module.tabs.slice(0, imageLimit));
    } else {
      const filter_data = module.tabs.filter((item) => item.image.id === id);
      setImageLimit(imageFixLimit);
      setGallery(filter_data.slice(0, imageLimit));
    }
  }

  return (
    <>
      {module.galleryType === 'Grid' ? (
        <section
          className={`img-grid gallery-img-grid portrait lg:py-60 py-30 ${module.extraClass}`}
        >
          <div className="container-fluid">
            <div className="container m-auto">
              <div className="filter-list text-center mdscreen:pb-10 mdscreen:border-b-[1px] mdscreen:border-b-[#666366]/40 lg:mb-60 mb-30 relative hidden">
                <select
                  onChange={(e) => {
                    filterData(e.target.value);
                  }}
                >
                  <option value="all">All Property Images</option>
                  {module.tabs.map((item, i) => {
                    return (
                      <option value={item.image.id}>{item.caption}</option>
                    );
                  })}
                </select>
              </div>
            </div>
            {module.heading && (
              <div className="title-black lg:pb-80 pb-40 text-center fade-ani">
                <h3>{module.heading}</h3>
              </div>
            )}
            <div className="flex flex-wrap lg:mx-minus-20 lg:gap-y-20 gap-y-10 ">
              {gallery &&
                gallery.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className={`lg:w-4/12 w-full lg:px-20 fade-img`}
                    >
                      <img
                        src={item.imgixImage.fluid.srcWebp}
                        alt={item.altText}
                        className="w-full object-cover"
                        width="890"
                        height="647"
                        loading="lazy"
                      />
                      <div className="img-content pt-30 max-w-540">
                        <span className="uppercase text-dark text-11 font-400 font-preheaders">
                          {item.caption}
                        </span>
                      </div>
                    </div>
                  );
                })}
            </div>
            {fixLimit > gallery.length && gallery.length > 1 && (
              <div className="btn-custom text-center justify-center cursor-pointer lg:mt-80 mt-40">
                <a
                  onClick={() => {
                    setImageLimit((imageLimit) => imageLimit + imageFixLimit);
                  }}
                  className="button button-transparent bg-[#e2e2e2]"
                >
                  Load More
                </a>
              </div>
            )}
          </div>
        </section>
      ) : (
        <section
          className={`full-bx-slider bg-white lg:py-120 py-60 ${module.extraClass}`}
        >
          <Swiper
            loop={true}
            centeredSlides={true}
            navigation={true}
            speed={1000}
            pagination={{
              type: 'progressbar',
            }}
            modules={[Pagination]}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 2.2,
                spaceBetween: 20,
              },
            }}
          >
            {module.tabs &&
              module.tabs.map((item, i) => {
                return (
                  <SwiperSlide key={i}>
                    <div className="card-bx">
                      <div className="gallery-img-slider relative">
                        <img
                          src={item?.image.imgixImage.fluid.srcWebp}
                          alt={item?.image.altText}
                          width="861"
                          height="517"
                          loading="lazy"
                        />
                        <div className="swiper-arrow  flex items-center justify-between w-full top-50-per translate-y-minus_50 px-20">
                          <a
                            className="button-prev cursor-pointer"
                            onClick={handlePrevClickStay}
                          ></a>
                          <a
                            className="button-next cursor-pointer"
                            onClick={handleNextClickStay}
                          ></a>
                        </div>
                      </div>
                      <div className="content pt-30 lg:pr-100 pr-0 smscreen2:text-center smscreen:px-20">
                        <h6>{item.heading}</h6>
                        <p>{item.caption && parse(item.caption)}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </section>
      )}
    </>
  );
};

export default Gallery;

export const GalleryFragment = graphql`
  fragment GalleryFragment on WpPage_Flexiblecontent_FlexibleContent_Gallery {
    # content
    fieldGroupName
    galleryType
    extraClass
    tabs {
      backgroundVideo
      caption
      fullVideo
      heading
      image {
        id
        title
        altText
        imgixImage {
          fluid(
            maxWidth: 861
            maxHeight: 517
            imgixParams: { w: 861, h: 517, fit: "crop" }
          ) {
            srcWebp
          }
        }
        uri
        title
      }
    }
    heading
  }
`;
export const GalleryBlogFragment = graphql`
  fragment GalleryBlogFragment on WpPost_Blogcontent_BlogContent_Gallery {
    # content
    extraClass
    fieldGroupName
    heading
    galleryType
    tabs {
      backgroundVideo
      caption
      fieldGroupName
      fullVideo
      heading
      image {
        altText
        imgixImage {
          fluid(
            maxWidth: 861
            maxHeight: 517
            imgixParams: { w: 861, h: 517, fit: "crop" }
          ) {
            srcWebp
          }
        }
        uri
        title
      }
    }
  }
`;
export const GalleryExperienceFragment = graphql`
  fragment GalleryExperienceFragment on WpExperience_Experiencescontent_ExperiencesContent_Gallery {
    # content
    fieldGroupName
    gallery {
      uri
      title
      caption
      imgixImage {
        fluid(
          maxWidth: 890
          maxHeight: 647
          imgixParams: { w: 890, h: 647, fit: "crop" }
        ) {
          srcWebp
        }
      }
      altText
    }
  }
`;
export const GalleryPropertyFragment = graphql`
  fragment GalleryPropertyFragment on WpProperty_Flexiblecontent_FlexibleContent_Gallery {
    # content
    fieldGroupName
    galleryType
    extraClass
    tabs {
      backgroundVideo
      caption
      fullVideo
      heading
      image {
        id
        title
        altText
        imgixImage {
          fluid(
            maxWidth: 861
            maxHeight: 517
            imgixParams: { w: 861, h: 517, fit: "crop" }
          ) {
            srcWebp
          }
        }
        uri
        title
      }
    }
    heading
  }
`;
