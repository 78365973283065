import { graphql } from 'gatsby';
import React from 'react';
import ExperiencesSlider from '../components/ExperiencesSlider';
import Gallery from '../components/Gallery';
import ImageContent from '../components/ImageContent';
import IntroContent from '../components/IntroContent';
import RelatedCards from '../components/RelatedCards';

const ModuleExperience = (props) => {
  const modules = props.modules.experiencesContent;
  const { location } = props;
  const components = {
    IntroContent,
    Gallery,
    RelatedCards,
    ExperiencesSlider,
    ImageContent,
  };

  return (
    <>
      {modules
        ? modules.map((module, i) => {
            const moduleName = module.__typename.replace(
              'WpExperience_Experiencescontent_ExperiencesContent_',
              ''
            );
            return (
              components[moduleName] &&
              React.createElement(components[moduleName], {
                key: i,
                module,
                location,
                i,
                modules: modules,
              })
            );
          })
        : null}
    </>
  );
};

export default ModuleExperience;

export const ModuleExperienceFragment = graphql`
  fragment ModuleExperienceFragment on WpExperience {
    experiencesContent {
      experiencesContent {
        __typename
        ...IntroContentExperienceFragment
        ...GalleryExperienceFragment
        ...RelatedCardsExperienceFragment
        ...ExperiencesSliderExperienceFragment
        ...ImageContentExperienceFragment
      }
    }
  }
`;
